<template>
  <div class="header_section" id="admin_navbar">
    <div class="header_top">
      <div class="container-fluid header_top_container">
        <ul class="navbar-nav">
          <li class="nav-item d-block d-xl-none">
            <a class="nav-link sidebartoggler nav-icon-hover" @click="toggleSidebar">
              <i id="menu-button" style="color:white" class="ti ti-menu-2"></i>
            </a>
          </li>
        </ul>
        <div class="lang_box dropdown">
          <a title="Language" class="nav-link" data-toggle="dropdown" aria-expanded="true">
            <img :src="selectedFlag" alt="flag" class="" title="">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" @click="changeLanguage('en')">
              <img src="images/flag/flag-en.png" class="" alt="flag">
            </a>
            <br>
            <a class="dropdown-item" @click="changeLanguage('tr')">
              <img src="images/flag/flag-tr.png" class="" alt="flag">
            </a>
          </div>
        </div>
        <div class="contact_nav">
          <a href="">
            <i class="fa fa-phone" aria-hidden="true"></i>
            <span>
              {{ $t('header.contact.call') }}
            </span>
          </a>
          <a href="">
            <i class="fa fa-envelope" aria-hidden="true"></i>
            <span>
              E-Mail:demo@veryfleet.net
            </span>
          </a>
          <a href="">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <span>
              {{ $t('header.contact.location') }}
            </span>
          </a>
        </div>
        <div class="social_box">
          <a href="">
            <i class="fa fa-facebook" aria-hidden="true"></i>
          </a>
          <a href="">
            <i class="fa fa-twitter" aria-hidden="true"></i>
          </a>
          <a href="">
            <i class="fa fa-linkedin" aria-hidden="true"></i>
          </a>
          <a href="">
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        </div>
        <li class="nav-item dropdown">
          <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
            aria-expanded="false">
            <img src="@/assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle">
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
            <div class="message-body">

              <router-link to="user" class="d-flex align-items-center gap-2 dropdown-item">
                <i class="ti ti-user fs-6"></i>
                <p class="mb-0 fs-3">{{ $t('navbar.profile') }}</p>
              </router-link>
              <a class="btn btn-outline-primary mx-3 mt-2 d-block" @click="logout">{{ $t('navbar.logout') }}</a>
            </div>
          </div>
        </li>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/server/axios'
import app from '@/main'
import VueJwtDecode from 'vue-jwt-decode'

export default {
  data() {
    return {
      selectedFlag: this.lang(),
      isMiniSidebar: false,
    }
  },
  created() {
    if (app.config.globalProperties.$cookies.get('lang') === 'tr') {
      this.selectedFlag = 'images/flag/flag-tr.png'
      this.$i18n.locale = 'tr'
    }
    if (app.config.globalProperties.$cookies.get('lang') === 'en') {
      this.selectedFlag = 'images/flag/flag-en.png'
      this.$i18n.locale = 'en'
    }
  },
  methods: {
    logout() {
      try {


        const currentTimestamp = Math.floor(Date.now() / 1000)
        if (currentTimestamp <= VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).exp) {
          axios.post('logout')
            .then(response => {
              if (response.status === 200) {
                app.config.globalProperties.$cookies.remove('authToken')
                app.config.globalProperties.$cookies.remove('lang')
                this.$router.push('login')
              }
            })
            .catch(error => {
              this.$router.push('login')
            })
        }
        else {
          this.$router.push('login')
        }
      }
      catch (error) {
        this.$router.push('login')
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang
      app.config.globalProperties.$cookies.set('lang', lang)
      if (lang === 'tr') {
        this.selectedFlag = 'images/flag/flag-tr.png'
        window.location.reload()
      } else if (lang === 'en') {
        this.selectedFlag = 'images/flag/flag-en.png'
        window.location.reload()
      }

    },
    toggleSidebar() {
      const sidebar = document.getElementById('left-sidebar')
      if (sidebar) {
        sidebar.style.display = 'block'
      }
    },
    lang() {
      return 'images/flag/flag-' + this.$i18n.locale + '.png'
    }
  }
}
</script>