<template>
  <!--  Body Wrapper -->
  <div>
    <SideBar />
    <NavBar />
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <div id="map-menu">
        <br>
        <!-- Last  Location -->
        <form @submit.prevent="live">
          <div class="row">
            <div class="col-md-12">
              <h2>{{ $t('gps.live') }}</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label">{{ $t('gps.vehicle') }}</label>
              <select class="form-select" v-model="oneCarSelect" required="" autofocus="" style="background-color:white;">
                <option value="" selected disabled>{{ $t('gps.carSelect') }}</option>
                <option value="0">{{ $t('gps.all') }}</option>
                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka }}</option>
              </select>
            </div>
          </div>
          <br>
          <button class="btn btn-lg btn-primary btn-block" type="submit" style="float: center; width:100%">{{
            $t('gps.submit')
          }}</button>
          <br><br>
          <div class="form-group">
            <a id="errorText">{{ this.errorTextLive }}</a>
          </div>
        </form>
        <br>
        <!-- One Day Route -->
        <form @submit.prevent="route">
          <div class="row">
            <div class="col-md-12">
              <h2>{{ $t('gps.route') }}</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label">{{ $t('gps.vehicle') }}</label>
              <select class="form-select" v-model="routeCarSelect.deviceID" required="" autofocus=""
                style="background-color:white;">
                <option value="" selected disabled>{{ $t('gps.carSelect') }}</option>
                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle.deviceID">{{ vehicle.plaka }}
                </option>
              </select>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label for="startDate" class="form-label">{{ this.$t('gps.date') }}</label>
              <VueDatePicker v-model="routeCarSelect.date" :enable-time-picker="false" :locale="this.changeLang()"
                :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"
                required="" />
            </div>
          </div>
          <br>
          <button class="btn btn-lg btn-primary btn-block" type="submit" style="float: center; width:100%">{{
            $t('gps.submit')
          }}</button>
          <br><br>
          <div class="form-group">
            <a id="errorText">{{ this.errorTextRoute }}</a>
          </div>
        </form>
        <br>
        <!-- Time Range Route -->
        <form @submit.prevent="timeRangeRouteMethod">
          <div class="row">
            <div class="col-md-12">
              <h2>{{ $t('gps.timeRangeRoute') }}</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label">{{ $t('gps.vehicle') }}</label>
              <select class="form-select" v-model="timeRangeRoute.deviceID" required="" autofocus=""
                style="background-color:white;">
                <option value="" selected disabled>{{ $t('gps.carSelect') }}</option>
                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle.deviceID">{{ vehicle.plaka }}
                </option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <label for="startDate" class="form-label">{{ this.$t('gps.date') }}</label>
              <VueDatePicker v-model="timeRangeRoute.date" :enable-time-picker="false" :locale="this.changeLang()"
                :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"
                required="" date-picker/>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <label for="startDate" class="form-label">{{ this.$t('gps.startTime') }}</label>
              <VueDatePicker v-model="timeRangeRoute.startTime" :locale="this.changeLang()"
                :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"
                required="" :max-time="timeRangeRoute.finishTime" time-picker/>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <label for="startDate" class="form-label">{{ this.$t('gps.finishTime') }}</label>
              <VueDatePicker v-model="timeRangeRoute.finishTime" :locale="this.changeLang()"
                :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"
                required="" :min-time="timeRangeRoute.startTime" time-picker/>
            </div>
          </div>
          <br>
          <button class="btn btn-lg btn-primary btn-block" type="submit" style="float: center; width:100%">{{
            $t('gps.submit')
          }}</button>
          <br><br>
          <div class="form-group">
            <a id="errorText">{{ this.errorTimeRangeRoute }}</a>
          </div>
        </form>
      </div>
      <GoogleMap id="map" :api-key="key" :map-id="mapID" :center="center" :zoom="5" :clickable-icons="false"
        :fullscreen-control="true" :language="mapLang" mapTypeId="roadmap">
        <Polyline v-if="this.polyline" :options="flightPath" />
        <Marker v-if="this.polyline" :options="startMarker"></Marker>
        <Marker v-if="this.polyline" :options="finishMarker"></Marker>
        <CustomMarker v-if="this.marker" :options="{ position: center, anchorPoint: 'BOTTOM_CENTER', }">
          <div style="text-align: center">
            <div style="font-size: 1.125rem; background-color:#3673A4; color:white;">{{ this.plate }}<br>{{ this.time }}
            </div>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADAklEQVR4nO2WT08TQRjGe9Iv4IEjV1tLqzSRELU7IweIFz1AQBPCJxCvSEMC0oQYYqIxxrQmhZ01Jh7gO0g8qPQCBbRVCQVb6ba02/InaRNeMt3+2S7dQlu6u5p9kue0aef5zbzvvGMyGTJkyJChVsvp22/DrOBBJLWDSQqqO1nd3J6CE1WNOH4Hk7jH6dttu5jw79NXEJsKYiKA6NYCYC6eN+LiP+jaTQMgIszQ4Hc8PNyYCIJtbA1sT0V3yGzNO1Dha+Nyr4JFZnPRrlWwTq7B7XeRAgTvbhoAE2GTAnRO/QTb2HrLAcyuFbC5v4snQfjNpsI7fcl2Gp6ZS4I9H14dAItrBZj5WB7C+SHZ3vjus8IIBbj15m8hvDoAZtcKdL/dKfbDSMMAiKR8FKBrNqw6gOPFb7EPCO9rvv4nQ6oDWKfXm+sDaf1fL4VXD8B83j7AJPW5dI+L9/M25hJ90vq3awBwVdYHDBHuiYO0lJOW1xItk/IgKn8IS+tfKwCHpA8wK2zLc9JvJqUP0vrXCsAq6QOljTZhTujDbGq7CgCgQv1rBWCW9IEcAJF4+C6b6D3VE2guY0EkfVB++8itzlsInzJ/hNhdW82bp/clXMZECGCSBh0CACaxQP9HuFTjRhJGxfA6BeB4YLjYY0UAxKa/6h0AkdgXZYB87ZcBhhYy4I/m4Ch3DP5IDgYXMi0HGFrcA380K64ZzcLg4l4lAMfv1yihYngRgIaXajmSazmAP5qVrZmtAKA+N8Bh7rjizw6yxy0HOKy2ZqMAtGz+6RMYWsjkQ9Nd+aZiDyxHsoU1T/dAXQBn3UKdU8VnduVklk7kahNYOnU7pjfquoUuFKD71Z+mAbpeh7UDQPNJsI9vNAxgmVgFho1rB0B98/lWwwCO2V91D7ILB2B8CXA8C4G9DgBquzsIzDyvPYDaTwn8XwP0eLd0D9DjDSkD3H8yA2UI/QH0eEPwYNStDDDwaBhq2aSSBhrNYQDo4ASWlH7U/3D4k4oAS3rIYciQIUOmM3UCul/C/JAqQk8AAAAASUVORK5CYII="
              width="30" height="30" style="margin-top: 8px">

          </div>
        </CustomMarker>
        <CustomMarker v-if="this.multiMarker" v-for="(location, i) in this.locations"
          :options="{ position: location, anchorPoint: 'BOTTOM_CENTER' }" :key="i">
          <div style="text-align: center">
            <div style="font-size: 1.125rem; background-color:#3673A4; color:white;">{{ this.multiLine[i].plate
            }}<br>{{ this.$t('gps.time') + this.multiLine[i].time }}
            </div>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADAklEQVR4nO2WT08TQRjGe9Iv4IEjV1tLqzSRELU7IweIFz1AQBPCJxCvSEMC0oQYYqIxxrQmhZ01Jh7gO0g8qPQCBbRVCQVb6ba02/InaRNeMt3+2S7dQlu6u5p9kue0aef5zbzvvGMyGTJkyJChVsvp22/DrOBBJLWDSQqqO1nd3J6CE1WNOH4Hk7jH6dttu5jw79NXEJsKYiKA6NYCYC6eN+LiP+jaTQMgIszQ4Hc8PNyYCIJtbA1sT0V3yGzNO1Dha+Nyr4JFZnPRrlWwTq7B7XeRAgTvbhoAE2GTAnRO/QTb2HrLAcyuFbC5v4snQfjNpsI7fcl2Gp6ZS4I9H14dAItrBZj5WB7C+SHZ3vjus8IIBbj15m8hvDoAZtcKdL/dKfbDSMMAiKR8FKBrNqw6gOPFb7EPCO9rvv4nQ6oDWKfXm+sDaf1fL4VXD8B83j7AJPW5dI+L9/M25hJ90vq3awBwVdYHDBHuiYO0lJOW1xItk/IgKn8IS+tfKwCHpA8wK2zLc9JvJqUP0vrXCsAq6QOljTZhTujDbGq7CgCgQv1rBWCW9IEcAJF4+C6b6D3VE2guY0EkfVB++8itzlsInzJ/hNhdW82bp/clXMZECGCSBh0CACaxQP9HuFTjRhJGxfA6BeB4YLjYY0UAxKa/6h0AkdgXZYB87ZcBhhYy4I/m4Ch3DP5IDgYXMi0HGFrcA380K64ZzcLg4l4lAMfv1yihYngRgIaXajmSazmAP5qVrZmtAKA+N8Bh7rjizw6yxy0HOKy2ZqMAtGz+6RMYWsjkQ9Nd+aZiDyxHsoU1T/dAXQBn3UKdU8VnduVklk7kahNYOnU7pjfquoUuFKD71Z+mAbpeh7UDQPNJsI9vNAxgmVgFho1rB0B98/lWwwCO2V91D7ILB2B8CXA8C4G9DgBquzsIzDyvPYDaTwn8XwP0eLd0D9DjDSkD3H8yA2UI/QH0eEPwYNStDDDwaBhq2aSSBhrNYQDo4ASWlH7U/3D4k4oAS3rIYciQIUOmM3UCul/C/JAqQk8AAAAASUVORK5CYII="
              width="30" height="30" style="margin-top: 8px">

          </div>
        </CustomMarker>
      </GoogleMap>
    </div>
  </div>
</template>
<script>
import SideBar from '@/components/admin/Sidebar'
import NavBar from '@/components/admin/Navbar'
import { defineComponent } from "vue"
import { GoogleMap, Marker, CustomMarker, Polyline, MarkerCluster } from "vue3-google-map"
import VueDatePicker from '@vuepic/vue-datepicker';
import axios from '@/server/axios'
import VueJwtDecode from 'vue-jwt-decode'
import app from '@/main'
import langChange from '@/components/admin/js/langChange'
import isNullOrUndefined from 'core-js/internals/is-null-or-undefined';

export default defineComponent({
  mixins: [langChange],
  components: { GoogleMap, Marker, CustomMarker, Polyline, MarkerCluster, SideBar, NavBar, VueDatePicker },
  data() {
    return {
      userID: {
        id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID,
        hash: window.location.hash.replace('#/', '')
      },
      vehicles: [],
      oneCarSelect: '',
      routeCarSelect: {
        deviceID: '',
        date: ''
      },
      timeRangeRoute:{
        deviceID: '',
        date: '',
        startTime:'',
        finishTime:''
      },
      isSuccess: false,
      errorTextRoute: null,
      errorTimeRangeRoute:null,
      errorTextLive: null,
      gps: [],
      center: { lat: 39.9032224, lng: 32.4509489 },
      plate: '',
      marker: false,
      polyline: false,
      multiMarker: false,
      mapLang: this.changeLang(),
      flightPlanCoordinates: [],
      flightPath: [],
      startMarker: [],
      finishMarker: [],
      locations: [],
      multiLine: [],
      time: null
    }
  },
  setup() {
    const key = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    const mapID = process.env.VUE_APP_GOOGLE_MAPS_ID
    return { key, mapID }
  },
  mounted() {
    this.getVehicles()
  },
  methods: {
    async getVehicles() {
      await axios.post('getVehicle', this.userID)
        .then(response => {
          this.vehicles = response.data.data;
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    live() {
      this.errorTextRoute = null
      this.polyline = false
      this.multiMarker = false
      this.marker = false
      if (this.oneCarSelect != 0) {
        axios.post('getOneVehicleLocation', this.oneCarSelect)
          .then(response => {
            if (response.status === 200) {
              this.errorTextLive = null
              const location = response.data.data
              this.marker = true
              this.center = { lat: parseFloat(location.konumEnlem), lng: parseFloat(location.konumBoylam) }
              this.plate = this.oneCarSelect.plaka
              this.time = this.$t('gps.time') + location.gercekSaat
            }
            if (response.status === 204) {
              this.marker = false
              this.errorTextLive = this.$t('gps.empty')
            }
            if (response.status === 203) {
              this.marker = false
              this.errorTextLive = response.data.message
            }
          })
          .catch(error => {
            this.marker = false
            console.log(error)
            this.errorTextLive = error.message
          })
      }
      if (this.oneCarSelect === '0') {
        axios.post('getAllVehiclesLocation', this.vehicles)
          .then(response => {
            if (response.status === 200) {
              this.multiMarker = true
              this.errorTextLive = null
              this.multiLine = response.data.data
              this.locations = response.data.data.map((coordinate) => ({
                lat: parseFloat(coordinate.lat),
                lng: parseFloat(coordinate.lng)
              }))
            }
            if (response.status === 204) {
              this.marker = false
              this.errorTextLive = this.$t('gps.empty')
            }
            if (response.status === 203) {
              this.marker = false
              this.errorTextLive = response.data.message
            }
          })
          .catch(error => {
            this.marker = false
            this.errorTextLive = error.message
          })
      }
    },
    route() {
      this.errorTextLive = null
      this.marker = false
      this.multiMarker = false
      axios.post('createRoute', this.routeCarSelect)
        .then(response => {
          if (response.status === 200) {
            this.errorTextRoute = null
            this.polyline = true
            this.flightPlanCoordinates = response.data.data.map((coordinate) => ({
              lat: parseFloat(coordinate.konumEnlem),
              lng: parseFloat(coordinate.konumBoylam)
            }))
            this.center = this.flightPlanCoordinates[0]
            this.startMarker = {
              position: this.flightPlanCoordinates[0],
              title: this.$t('gps.startPosition') + ' ' + this.$t('gps.time') + response.data.data[0].gercekSaat,
              icon: require('@/assets/images/placeholder.png')
            }
            this.finishMarker = {
              position: this.flightPlanCoordinates[this.flightPlanCoordinates.length - 1],
              title: this.$t('gps.finishPosition') + ' ' + this.$t('gps.time') + response.data.data[response.data.data.length - 1].gercekSaat
            }
            this.flightPath = {
              path: this.flightPlanCoordinates,
              geodesic: true,
              strokeColor: "#5D87FF",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }
          if (response.status === 203) {
            this.polyline = false
            this.errorTextRoute = response.data.message
          }
          if (response.status === 204) {
            this.polyline = false
            this.errorTextRoute = this.$t('gps.empty')
          }
        })
        .catch(error => {
          this.errorTextRoute = error.message
        })
    },
    timeRangeRouteMethod() {
      this.errorTextLive = null
      this.marker = false
      this.multiMarker = false
      if (this.timeRangeRoute.startTime.hours < 10) {
        this.timeRangeRoute.startTime.hours = '0' + this.timeRangeRoute.startTime.hours
      }
      if (this.timeRangeRoute.startTime.minutes < 10) {
        this.timeRangeRoute.startTime.minutes = '0' + this.timeRangeRoute.startTime.minutes
      }
      if (this.timeRangeRoute.finishTime.hours < 10) {
        this.timeRangeRoute.finishTime.hours = '0' + this.timeRangeRoute.finishTime.hours
      }
      if (this.timeRangeRoute.finishTime.minutes < 10) {
        this.timeRangeRoute.finishTime.minutes = '0' + this.timeRangeRoute.finishTime.minutes
      }
      this.timeRangeRoute.startTime = this.timeRangeRoute.startTime.hours + ':'+ this.timeRangeRoute.startTime.minutes + ':00'
      this.timeRangeRoute.finishTime = this.timeRangeRoute.finishTime.hours + ':'+ this.timeRangeRoute.finishTime.minutes + ':00'
      axios.post('timeRangeRoute', this.timeRangeRoute)
        .then(response => {
          if (response.status === 200) {
            this.errorTextRoute = null
            this.polyline = true
            this.flightPlanCoordinates = response.data.data.map((coordinate) => ({
              lat: parseFloat(coordinate.konumEnlem),
              lng: parseFloat(coordinate.konumBoylam)
            }))
            this.center = this.flightPlanCoordinates[0]
            this.startMarker = {
              position: this.flightPlanCoordinates[0],
              title: this.$t('gps.startPosition') + ' ' + this.$t('gps.time') + response.data.data[0].gercekSaat,
              icon: require('@/assets/images/placeholder.png')
            }
            this.finishMarker = {
              position: this.flightPlanCoordinates[this.flightPlanCoordinates.length - 1],
              title: this.$t('gps.finishPosition') + ' ' + this.$t('gps.time') + response.data.data[response.data.data.length - 1].gercekSaat
            }
            this.flightPath = {
              path: this.flightPlanCoordinates,
              geodesic: true,
              strokeColor: "#5D87FF",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            }
          }
          if (response.status === 203) {
            this.polyline = false
            this.errorTimeRangeRoute = response.data.message
          }
          if (response.status === 204) {
            this.polyline = false
            this.errorTimeRangeRoute = this.$t('gps.empty')
          }
        })
        .catch(error => {
          this.errorTimeRangeRoute = error.message
        })
        this.timeRangeRoute.date = null
        this.timeRangeRoute.startTime = null
        this.timeRangeRoute.finishTime = null
    }
  },
  watch: {
    '$i18n.locale': 'changeLang',
  }
});
</script>
