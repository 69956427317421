<template>
    <div>
        <SideBar />
        <NavBar />
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
                <div class="container dataTableContainer">
                    <DataTable :data="users" :columns="Columns" class="table table-striped table-bordered display"
                        id="userDatatable" :options="dataTableOptionsWithButtons">
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>{{ this.$t('driverView.name') }}</th>
                                <th>{{ this.$t('driverView.lastName') }}</th>
                                <th>{{ this.$t('driverView.identyID') }}</th>
                                <th>{{ this.$t('driverView.phoneNumber') }}</th>
                                <th>{{ this.$t('driverView.edit') }}</th>
                                <th>{{ this.$t('driverView.delete') }}</th>
                            </tr>
                        </thead>
                    </DataTable>
            </div>
            <!-- Modal driver -->
            <div class="modal fade" id="driverModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{ this.$t('driverView.heading') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form v-if="users">
                                <div class="mb-3">
                                    <label for="ad" class="form-label">{{ this.$t('driverView.name') }}</label>
                                    <input type="text" class="form-control" id="ad" v-model="editedDriver.name"
                                        @input="onInput" maxlength="50" required>
                                </div>
                                <div class="mb-3">
                                    <label for="soyad" class="form-label">{{ this.$t('driverView.lastName') }}</label>
                                    <input type="text" class="form-control" id="soyad" v-model="editedDriver.lastName"
                                        @input="onInput" maxlength="50" required>
                                </div>
                                <div class="mb-3">
                                    <label for="identyID" class="form-label">{{ this.$t('driverView.identyID') }}</label>
                                    <input type="text" class="form-control" id="identyID" v-model="editedDriver.identyID"
                                        maxlength="11" @input="onInput" required>
                                </div>

                                <div class="mb-3">
                                    <label for="cepTelefonu">{{ this.$t('driverView.phoneNumber') }}</label>
                                    <MazPhoneNumberInput v-model="editedDriver.phoneNumber" show-code-on-list color="info"
                                        :preferred-countries="['FR', 'BE', 'DE', 'US', 'GB', 'TR']"
                                        :ignored-countries="['AC']" @update="results = $event"
                                        :success="results && isValid" />
                                </div>
                            </form>
                        </div>
                        <a v-if="isSuccess" id="successText">{{ $t('driverView.success') }}</a>
                        <a id="errorText">{{ this.errorText }}</a>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{
                                this.$t('driverView.close') }}</button>
                            <button type="button" @click="saveChanges" class="btn btn-primary">{{ this.$t('driverView.save')
                            }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'

import langChange from '@/components/admin/js/langChange'
import exportToExcelMixin from '@/components/admin/js/exportToExcel'

import VueJwtDecode from 'vue-jwt-decode'
import app from '@/main'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons)
export default {
    mixins: [exportToExcelMixin, langChange],
    data() {
        return {
            userID: {
                id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID
            },
            results: false,
            isValid: false,
            users: [],
            selectedDriver: null,
            editedDriver: [],
            isSuccess: false,
            errorText: null,
            Columns: [
                { data: (data, type, row, meta) => meta.row + 1 },
                { data: 'name' },
                { data: 'lastName' },
                { data: 'identyID' },
                { data: 'phoneNumber' },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-settings edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,
                lengthMenu: [10, 25, 50, 100],
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    {
                        text: this.$t('driverView.convertExcel'),
                        action: this.exportExcel
                    }
                ],
                language: {
                    url: this.lang()
                },
            }
        }
    },
    components: {
        SideBar,
        NavBar,
        MazPhoneNumberInput,
        DataTable,
    },
    mounted() {

        this.getDrivers()

        const self = this;
        $('#userDatatable').on('click', '.edit-button', function () {
            const driverID = $(this).data('id')
            const driver = self.users.find(driver => driver.id === driverID)
            if (driver) {
                self.selectedDriver = driver;
                self.editedDriver = { ...driver }
                $('#driverModal').modal('show')
            }
        })

        $('#userDatatable').on('click', '.delete-button', function () {
            const driverID = $(this).data('id')
            self.deleteDriver(driverID);
        })
        $('#driverModal').on('hidden.bs.modal', () => {
            this.errorText = null
            this.isSuccess = false

        })
    },
    methods: {
        getDrivers() {
            axios.post('drivers', this.userID)
                .then(response => {
                    if (response.data.status === true) {
                        this.users = response.data.data
                    }
                    else {
                        console.log(response.data.message)
                    }

                })
                .catch(error => {
                    console.log(error.message)
                });
        },
        async saveChanges() {
            this.successText = null
            if (this.results.isValid === false) {
                this.errorText = 'Phone number format error!'
            } else {
                await axios.post('update-driver', this.editedDriver)
                    .then(response => {
                        if (response.status === 200) {
                            const index = this.users.findIndex(driver => driver.id === this.editedDriver.id)
                            this.users[index] = this.editedDriver
                            this.errorText = null
                            this.isSuccess = true
                        }
                        if (response.status === 203) {
                            this.isSuccess = false
                            this.errorText = response.data.message
                        }
                        if (response.status === 204) {
                            this.isSuccess = false
                            this.errorText = this.$t('update.error')
                        }

                    })
                    .catch(error => {
                        this.isSuccess = false
                        this.errorText = error.message
                    })
            }

        },
        deleteDriver(driverID) {
            axios.delete(`delete-driver/${driverID}`)
                .then(response => {
                    if (response.status === 200) {
                        this.users = this.users.filter(driver => driver.id !== driverID)
                    }
                    if (response.status === 203) {
                        alert(this.$t('delete.error'))
                    }
                })
                .catch(error => {
                    alert(error.message)
                });

        },
        exportExcel() {
            const columnName = ['id', this.$t('driverView.name'), this.$t('driverView.lastName'), this.$t('driverView.identyID'), this.$t('driverView.phoneNumber')]
            this.exportToExcel(this.users, "drivers", columnName)
        },
        onInput() {
            if (this.editedDriver.name) {
                this.editedDriver.name = this.editedDriver.name.toUpperCase()
            }
            if (this.editedDriver.lastName) {
                this.editedDriver.lastName = this.editedDriver.lastName.toUpperCase()
            }
            if (this.editedDriver.identyID) {
                this.editedDriver.identyID = this.editedDriver.identyID.toString().replace(/\D/g, '')
            }
        },
        watchLang(newLocale, oldLocale) {
            this.dataTableOptionsWithButtons.buttons[0].text = this.$t('driverView.convertExcel')
        }
    },
    watch: {
        '$i18n.locale': 'watchLang'
    },
}

</script>
  
<style scoped>
#usersTable {
    width: 100%;
}

.modal-body {
    max-height: 60vh;
    overflow-y: auto;
}

.container {
    min-height: 650px;
}

#successText {
    margin-left: 30px;
    color: green;
}

#errorText {
    margin-left: 30px;
    color: red;
}
</style>
  