<template>
    <div>
        <SideBar />
        <NavBar />
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
                <div class="container dataTableContainer">
                    <DataTable :data="CarDriver" :columns="columns" class="table table-striped table-bordered display"
                        id="CarDriverDatatable" :options="dataTableOptionsWithButtons">
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>{{ this.$t('vehicleandriver.identyID') }}</th>
                                <th>{{ this.$t('vehicleandriver.name') }}</th>
                                <th>{{ this.$t('vehicleandriver.lastName') }}</th>
                                <th>{{ this.$t('vehicleandriver.plate') }}</th>
                                <th>{{ this.$t('vehicleandriver.delete') }}</th>
                            </tr>
                        </thead>
                    </DataTable>
                </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from "@/components/admin/Sidebar.vue"
import NavBar from "@/components/admin/Navbar.vue"
import axios from "@/server/axios"
import DataTable from 'datatables.net-vue3'

import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'

import langChange from '@/components/admin/js/langChange'
import exportToExcelMixin from '@/components/admin/js/exportToExcel'
import VueJwtDecode from 'vue-jwt-decode'
import app from '@/main'

DataTable.use(DataTableLength)


DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons)
export default {
    mixins: [exportToExcelMixin, langChange],
    data() {
        return {
            userID: {
                id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID
            },
            CarDriver: [],

            columns: [
                { data: (data, type, row, meta) => meta.row + 1 },
                { data: 'identyID' },
                { data: "name" },
                { data: "lastName" },
                { data: "car_plate" },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                },
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    {
                        text: this.$t('vehicleandriver.convertExcel'), // Buton metni
                        action: this.exportExcel // Excel'e aktarma işlevi
                    }
                ],


                language: {
                    url: this.lang()
                },


            },
        };
    },
    components: {
        SideBar,
        NavBar,
        DataTable,
    },


    mounted() {
        this.getDriverCar();
        const self = this;
        $('#CarDriverDatatable').on('click', '.delete-button', function () {
            const vehicleId = $(this).data('id');
            self.deleteVehicle(vehicleId);
        });

    },
    methods: {
        getDriverCar() {
            axios.post("driver-car", this.userID)
                .then((response) => {
                    if (response.status === 200) {
                        this.CarDriver = response.data.data
                    }
                })
                .catch((error) => {
                    console.log(error.message)
                });
        },

        async deleteVehicle(vehicleId) {
            try {
                const response = await axios.delete(`delete-car-driver/${vehicleId}`)

                if (response.status === 200) {
                    this.CarDriver = this.CarDriver.filter(vehicle => vehicle.id !== vehicleId)
                } else {
                    alert(this.$t('delete.error'))
                }
            } catch (error) {
                alert(error.message)
            }
        },
        watchLang(newLocale, oldLocale) {
            this.dataTableOptionsWithButtons.buttons[0].text = this.$t('vehicleandriver.convertExcel')
        },
        exportExcel() {
            const columnName = ['id', this.$t('vehicleandriver.name'), this.$t('vehicleandriver.lastName'), this.$t('vehicleandriver.identyID'), this.$t('vehicleandriver.plate')]
            this.exportToExcel(this.CarDriver, "vehicleandriver", columnName)
        }
    },
    watch: {
        '$i18n.locale': 'watchLang'
    },
};
</script>
  
<style scoped>
#vehiclesTable {
    width: 100%;
}

.container {
    min-height: 650px;
}
</style>
  