<template>
  <!--  Body Wrapper -->
  <div>
    <SideBar />
    <NavBar />
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <div class="wrapper">
        <div class="container">
          <form @submit.prevent="driverCreate">
            <div class="row">
              <div class="col-md-12">
                <h2>{{ $t('createDriver.heading') }}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <label>{{ $t('createDriver.name') }}</label>
                <input type="text" class="form-control" v-model="driver.name" name="name" required="" autofocus=""
                  @input="onInput" maxlength="50">
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <label>{{ $t('createDriver.lastname') }}</label>
                <input type="text" class="form-control" v-model="driver.lastName" name="lastname" required=""
                  @input="onInput" maxlength="50 ">
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <label>{{ $t('createDriver.phoneNumber') }}</label>
                <MazPhoneNumberInput v-model="driver.phoneNumber" show-code-on-list color="info"
                  :preferred-countries="['FR', 'BE', 'DE', 'US', 'GB', 'TR']" :ignored-countries="['AC']"
                  @update="results = $event" :success="results && isValid" />
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <label>{{ $t('createDriver.identityID') }}</label>
                <input type="text" class="form-control" v-model="driver.identyID" name="identyID" required="" @input="onInput" maxlength="11">
              </div>
            </div>
            <br>
            <button class="btn btn-lg btn-primary btn-block" type="submit">{{ $t('createDriver.submit') }}</button>
            <br><br>
            <div class="form-group">
              <a v-if="isSuccess" id="successText">{{ $t('createDriver.success') }}</a>
              <a id="errorText">{{ this.errorText }}</a>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>
<script scoped>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import VueJwtDecode from 'vue-jwt-decode'
import app from '@/main'
export default {
  components: {
    SideBar,
    NavBar,
    MazPhoneNumberInput
  },
  data() {
    return {
      users: [],
      phoneNumber: null,
      driver: [],
      errorText: null,
      results: false,
      isValid: false,
      isSuccess: false
    };
  },
  mounted() {
  },
  methods: {
    driverCreate() {
      this.errorText = null
      this.successText = null
      this.driver.userID = VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID
      this.driverCopy = { ... this.driver }
      if (this.results.isValid === false) {
        if (app.config.globalProperties.$cookies.get('lang') === 'tr') {
          this.errorText = 'Telefon numarası formatı hatalı!'
        } else if (app.config.globalProperties.$cookies.get('lang') === 'en') {
          this.errorText = 'Phone number format error!'
        }
      } else {
        axios.post('create-driver', this.driverCopy)
          .then(response => {
                if (response.status === 200) {
                  this.errorText = null
                  this.isSuccess = true
                } if (response.status === 203) {
                  this.isSuccess = false
                  this.errorText = response.data.message
                }
            }
          )
          .catch(error => {
            this.errorText = error.message
          });
      }

    },
    onInput() {
      if (this.driver.name) {
        this.driver.name = this.driver.name.toUpperCase()
      }
      if (this.driver.lastName) {
        this.driver.lastName = this.driver.lastName.toUpperCase()
      }
      if (this.driver.identyID) {
        this.driver.identyID = this.driver.identyID.replace(/\D/g, '')
      }
    },
  }
}
</script>
<style scoped>
#errorText {
  color: red;
}

#successText {
  color: green;
}

.body-wrapper {
  margin-top: 5%;

}

.RegisterPage {
  margin-bottom: 10%;
}

select {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.205);
  padding: 7px;
}
</style>