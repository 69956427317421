<template>
    <div class="taxesform">
        <SideBar />
        <NavBar />
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <!-- Sidebar Start -->
            <div class="wrapper">
                <div class="container">
                    <form>
                        <h2>{{ $t('insurance.heading') }}</h2>
                        <div class="form-group">
                            <label for="vehicle">{{ $t('insurance.vehicles') }}</label>
                            <select v-model="selectedVehicle">
                                <option value="">{{ $t('insurance.selectVehicle') }}</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka }}
                                </option>
                            </select>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <button type="button" class="btn btn-success" @click="insuranceInformation">{{
                                $t('insurance.search') }}</button>
                            <button type="button" class="btn btn-info" @click="insuranceAdd">{{ $t('insurance.add')
                            }}</button>
                        </div>
                        <div class="form-group">
                            <a id="formText">{{ this.formText }}</a>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Modal taxe -->
            <div class="modal fade" id="insuranceModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog custom-modal">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{ this.$t('insurance.heading') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body dataTableContainer">

                            <DataTable :data="insurance" :columns="columns"
                                class="table table-striped table-bordered display" id="insuranceDatatable"
                                :options="dataTableOptionsWithButtons">
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>{{ this.$t('insurance.plate') }}</th>
                                        <th>{{ this.$t('insurance.insuranceCompany') }}</th>
                                        <th>{{ this.$t('insurance.year') }}</th>
                                        <th>{{ this.$t('insurance.startDate') }}</th>
                                        <th>{{ this.$t('insurance.finishDate') }}</th>
                                        <th>{{ this.$t('insurance.edit') }}</th>
                                        <th>{{ this.$t('insurance.delete') }}</th>
                                    </tr>
                                </thead>
                            </DataTable>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{
                                this.$t('insurance.close') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal edit -->
            <div class="modal fade" id="insuranceEditModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{ this.$t('insurance.editHeading') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form v-if="editedInsurance.id">
                                <div class="mb-3">
                                    <label for="insuranceCompany" class="form-label">{{
                                        this.$t('insurance.insuranceCompany') }}</label>
                                    <input type="text" v-model="editedInsurance.insuranceCompany">
                                </div>
                                <div class="mb-3">
                                    <label for="year" class="form-label">{{ this.$t('insurance.year') }}</label>
                                    <VueDatePicker v-model="editedInsurance.year" year-picker :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                                </div>
                                <div class="mb-3">
                                    <label for="startDate" class="form-label">{{ this.$t('insurance.startDate') }}</label>
                                    <VueDatePicker v-model="editedInsurance.startDate" :enable-time-picker="false"
                                        :min-date="minDate" :max-date="maxDate" :format="format" :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                                </div>
                                <div class="mb-3">
                                    <label for="finishDate" class="form-label">{{ this.$t('insurance.finishDate') }}</label>
                                    <VueDatePicker v-model="editedInsurance.finishDate" :enable-time-picker="false"
                                        :min-date="minDate" :max-date="maxDateFinish" :format="format" :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                                </div>
                            </form>
                        </div>
                        <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{
                                this.$t('insurance.close') }}</button>
                            <button type="button" @click="saveChanges" class="btn btn-primary">{{ this.$t('insurance.save')
                            }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal add -->
            <div class="modal fade" id="insuranceAddModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{ this.$t('insurance.addHeading') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form v-if="selectedVehicle.deviceID">
                                <div class="mb-3">
                                    <label for="year" class="form-label">{{ this.$t('insurance.insuranceCompany') }}</label>
                                    <input type="text" v-model="addInsurance.insuranceCompany">
                                </div>
                                <div class="mb-3">
                                    <label for="year" class="form-label">{{ this.$t('insurance.year') }}</label>
                                    <VueDatePicker v-model="addInsurance.year" year-picker :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                                </div>
                                <div class="mb-3">
                                    <label for="startDate" class="form-label">{{ this.$t('insurance.startDate') }}</label>
                                    <VueDatePicker v-model="addInsurance.startDate" :enable-time-picker="false"
                                        :min-date="minDateAddModal" :max-date="maxDateAddModal" :format="format" :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')" />
                                </div>
                                <div class="mb-3">
                                    <label for="finishDate" class="form-label">{{ this.$t('insurance.finishDate') }}</label>
                                    <VueDatePicker v-model="addInsurance.finishDate" :enable-time-picker="false"
                                        :min-date="minDateAddModal" :max-date="maxDateFinishAddModal" :format="format"
                                        :locale="this.changeLang()" :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')" />
                                </div>
                            </form>
                        </div>
                        <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{
                                this.$t('insurance.close') }}</button>
                            <button type="button" @click="insuranceAddButton" class="btn btn-primary">{{
                                this.$t('insurance.save') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, computed } from 'vue'
import VueJwtDecode from 'vue-jwt-decode'
import langChange from '@/components/admin/js/langChange'
import exportToExcelMixin from '@/components/admin/js/exportToExcel'
import app from '@/main'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons);

export default {
    mixins: [exportToExcelMixin, langChange],
    components: {
        SideBar,
        NavBar,
        DataTable,
        VueDatePicker,
    },
    data() {
        return {
            userID: {
                id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID,
                hash: window.location.hash.replace('#/', '')
            },
            formText: null,
            vehicles: [],
            selectedVehicle: '',
            insurance: [],
            editedInsurance: [],
            addInsurance: [],
            saveChangesText: '',
            errorText: '',
            date: null,
            minDate: null,
            maxDate: null,
            maxDateFinish: null,
            minDateAddModal: null,
            maxDateAddModal: null,
            maxDateFinishAddModal: null,
            format: null,
            isSuccess: true,
            oldValue: null,
            columns: [
                { data: (data, type, row, meta) => meta.row + 1 },
                { data: 'plate' },
                { data: 'insuranceCompany' },
                { data: 'year', },
                { data: 'startDate' },
                { data: 'finishDate' },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-settings edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    {
                        text: this.$t('tax.convertExcel'),
                        action: this.exportExcel
                    }
                ],
                language: {
                    url: this.lang()
                },
            },
        };
    },
    computed: {
        getStyle() {
            return {
                color: this.isSuccess ? 'green' : 'red'
            }
        }
    },
    watch: {
        '$i18n.locale': 'watchLang',
        'addInsurance.year': function (newValue) {
            // Yeni bir tarih seçildiğinde buraya gelecek
            this.addInsurance.startDate = null
            this.addInsurance.finishDate = null
        },
        'editedInsurance.year': function (newValue) {
            // Yeni bir tarih seçildiğinde buraya gelecek
            if (this.oldValue !== newValue) {
                this.editedInsurance.startDate = null
                this.editedInsurance.finishDate = null
            }
        }
    },
    mounted() {
        this.getVehicles()
        const self = this
        const year = ref(new Date().getFullYear())
        this.minDateAddModal = computed(() => new Date(this.addInsurance.year, 0, 1))
        this.maxDateAddModal = computed(() => new Date(this.addInsurance.year, 11, 31))
        this.maxDateFinishAddModal = computed(() => new Date(this.addInsurance.year + 1, 11, 31))
        this.format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        $('#insuranceDatatable').on('click', '.edit-button', function () {
            const insuranceID = $(this).data('id')
            const ins = self.insurance.find(ins => ins.id === insuranceID)
            if (ins) {
                self.editedInsurance.id = ins.id
                self.editedInsurance.year = ins.year
                self.editedInsurance.startDate = ins.startDate
                self.editedInsurance.carID = ins.carID
                self.editedInsurance.plate = ins.plate
                self.editedInsurance.finishDate = ins.finishDate
                self.editedInsurance.insuranceCompany = ins.insuranceCompany
                self.minDate = computed(() => new Date(self.editedInsurance.year, 0, 1))
                self.maxDate = computed(() => new Date(self.editedInsurance.year, 11, 31))

                self.maxDateFinish = computed(() => new Date(Number(self.editedInsurance.year) + 1, 11, 31))
                console.log(self.maxDateFinish)
                self.saveChangesText = null
                self.oldValue = ins.year
                $('#insuranceEditModal').modal('show')
            }
        })
        $('#insuranceDatatable').on('click', '.delete-button', function () {
            const insID = $(this).data('id');
            self.deleteInsurance(insID);
        })

    },
    methods: {

        async getVehicles() {
            await axios.post('getVehicle', this.userID)
                .then(response => {
                    this.vehicles = response.data.data;
                })
                .catch(error => {
                    console.log(error.message);
                });
        },
        async insuranceInformation() {

            await axios.post('insuranceInformation', this.selectedVehicle)
                .then(response => {
                    if (response.status === 200) {
                        this.formText = null
                        this.insurance = response.data.data
                        $('#insuranceModal').modal('show')
                    }
                    if (response.status === 204) {
                        this.formText = this.$t('insurance.empty')
                    }
                    if (response.status === 203) {
                        this.formText = this.$t('insurance.carSelectError')
                    }
                })
                .catch(error => {
                    this.formText = error.message
                });
        },
        async saveChanges() {

            const editedInsuranceCopy = { ...this.editedInsurance }
            await axios.post('updateInsurance', editedInsuranceCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = this.$t('insurance.updateSuccess')
                        this.updateTableRow(editedInsuranceCopy.id)
                    }
                    if (response.status === 204) {
                        this.isSuccess = false
                        this.saveChangesText = this.$t('insurance.failed')
                    }
                    if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        updateTableRow(updatedRowId) {
            const updatedInsurance = this.insurance.find(ins => ins.id === updatedRowId)
            if (updatedInsurance) {
                updatedInsurance.insuranceCompany = this.editedInsurance.insuranceCompany
                updatedInsurance.year = this.editedInsurance.year
                updatedInsurance.startDate = this.formatDate(new Date(this.editedInsurance.startDate))
                updatedInsurance.finishDate = this.formatDate(new Date(this.editedInsurance.finishDate))
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz ve iki basamaklı hale getiriyoruz
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async deleteInsurance(insID) {
            await axios.delete(`deleteInsurance/${insID}`)
                .then(response => {
                    if (response.data.status === true) {
                        this.insurance = this.insurance.filter(ins => ins.id !== insID)
                    }
                    if (response.status === 203) {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    alert(error.message)
                })
        },
        insuranceAdd() {
            if (this.selectedVehicle.id) {
                this.formText = null
                this.saveChangesText = null
                $('#insuranceAddModal').modal('show')
            }
            else {
                this.formText = this.$t('insurance.carSelectError')
            }

        },
        async insuranceAddButton() {
            const addInsuranceCopy = { ...this.addInsurance }
            addInsuranceCopy.carID = this.selectedVehicle.id
            addInsuranceCopy.numberPlate = this.selectedVehicle.plaka
            await axios.post('addInsurance', addInsuranceCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = this.$t('insurance.success')
                    }
                    if (response.status === 204) {
                        this.isSuccess = false
                        this.saveChangesText = this.$t('insurance.failed')
                    }

                    if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        watchLang(newLocale, oldLocale) {
            this.dataTableOptionsWithButtons.buttons[0].text = this.$t('insurance.convertExcel')
        },
        exportExcel() {
            console.log(this.insurance)
            const columnName = ['id', this.$t('insurance.year'), 
            this.$t('insurance.startDate'), 
            this.$t('insurance.finishDate'), 
            this.$t('insurance.insuranceCompany'),
            this.$t('insurance.plate')
        ]
            const insurancesWithoutCarID = this.insurance.map(ins => {
                const insuranceWithoutCarID = {};
                Object.keys(ins).forEach(key => {
                    if (key !== 'carID') {
                        insuranceWithoutCarID[key] = ins[key];
                    }
                });
                return insuranceWithoutCarID;
            });
            this.exportToExcel(insurancesWithoutCarID, "insurances", columnName)
        }

    }
}
</script>
  
<style scoped>
#formText {
    color: red;
}

.text-success {
    color: green;
}

#taxesModal {
    margin-right: auto;
}

.custom-modal {
    max-width: 90%;
}

input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
</style>
   