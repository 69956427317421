<template>
  <div class="Assignform">
    <!-- Sidebar Start -->
    <SideBar />
    <NavBar />
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
      data-sidebar-position="fixed" data-header-position="fixed">
      <div class="wrapper">
        <div class="container">
          <form @submit.prevent="assingCreate">
            <div class="row">
              <div class="col-md-12">
                <h2>{{ $t('assingDriver.heading') }}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label>{{ $t('assingDriver.vehicle') }}</label>
                <select class="form-select" v-model="information.carID" required="" autofocus="">
                  <option value="">{{ $t('assingDriver.selectVehicle') }}</option>
                  <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle.id">{{ vehicle.plaka }}</option>
                </select>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label>{{ $t('assingDriver.driver') }}</label>
                <select class="form-select" v-model="information.driverID" required="">
                  <option value="">{{ $t('assingDriver.selectDriver') }}</option>
                  <option v-for="driver in drivers" :key="driver.id" :value="driver.id">{{ driver.name + ' ' +
                    driver.lastName
                    + ' - ' + driver.identyID }}</option>
                </select>
              </div>
            </div>
            <br>
            <button class="btn btn-lg btn-primary btn-block" type="submit">{{ $t('assingDriver.submit') }}</button>
            <br><br>
            <div class="form-group">
              <a v-if="isSuccess" id="successText">{{ $t('assingDriver.success') }}</a>
              <a id="errorText">{{ this.errorText }}</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import VueJwtDecode from 'vue-jwt-decode'
import axios from '@/server/axios'
import app from '@/main'
export default {
  components: {
    SideBar,
    NavBar,
  },
  data() {
    return {
      drivers: [],
      vehicles: [],
      information: {
        driverID: '',
        carID: '',
      },
      errorText: null,
      successText: null,
      userID: {
        id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID
      },
      isSuccess: false
    }
  },
  mounted() {
    this.getDriver()
    this.getVehicles()
  },
  methods: {
    getDriver() {
      axios.post('drivers', this.userID)
        .then(response => {
          this.drivers = response.data.data;
        })
        .catch(error => {
          this.errorText = error.message
        });
    },
    getVehicles() {
      this.userID.hash = window.location.hash.replace('#/', '')
      axios.post('getVehicle', this.userID)
        .then(response => {
          this.vehicles = response.data.data
        })
        .catch(error => {
          this.errorText = error.message
        });
    },
    assingCreate() {
      axios.post('driver-assing', this.information)
        .then(response => {
          if (response.status === 200) {
            this.errorText = null
            this.isSuccess = true
          } 
          if (response.status === 203) {
            this.isSuccess = false
            this.errorText = response.data.message
          }
        }
        )
        .catch(error => {
          this.isSuccess = false
          this.errorText = error.message
        })
    }
  }
}
</script>
<style scoped>
#errorText {
  color: red;
}

#successText {
  color: green;
}
</style>
