<template>
    <div class="taxesform">
        <SideBar />
        <NavBar />
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <!-- Sidebar Start -->
            <div class="wrapper">
                <div class="container">
                    <form>
                        <div class="form-group">
                            <h2>{{ $t('maintenance.heading') }}</h2>
                            <label for="vehicle">{{ $t('maintenance.vehicles') }}</label>
                            <select v-model="selectedVehicle">
                                <option value="">{{ $t('maintenance.selectVehicle') }}</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka }}
                                </option>
                            </select>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <button type="button" class="btn btn-success" @click="information">{{ $t('maintenance.search')
                            }}</button>
                            <button type="button" class="btn btn-info" @click="add">{{ $t('maintenance.add') }}</button>
                        </div>
                        <div class="form-group">
                            <a id="formText">{{ this.formText }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal information -->
        <div class="modal fade" id="maintenanceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog custom-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ $t('maintenance.heading') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body dataTableContainer">

                        <DataTable :data="maintenance" :columns="columns" class="table table-striped table-bordered display"
                            id="maintenanceDatatable" :options="dataTableOptionsWithButtons">
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>{{ this.$t('maintenance.plate') }}</th>
                                    <th>{{ this.$t('maintenance.company') }}</th>
                                    <th>{{ this.$t('maintenance.date') }}</th>
                                    <th>{{ this.$t('maintenance.km') }}</th>
                                    <th>{{ this.$t('maintenance.price') }}</th>
                                    <th>{{ this.$t('maintenance.note') }}</th>
                                    <th>{{ this.$t('maintenance.edit') }}</th>
                                    <th>{{ this.$t('maintenance.delete') }}</th>
                                </tr>
                            </thead>
                        </DataTable>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('maintenance.close')
                        }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal edit -->
        <div class="modal fade" id="maintenanceEditModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ $t('maintenance.editHeading') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form v-if="editedMaintenance.id">
                            <div class="mb-3">
                                <label for="maintenanceCompany" class="form-label">{{ $t('maintenance.company') }}</label>
                                <input type ="text" v-model="editedMaintenance.maintenanceCompany">
                            </div>
                            <div class="mb-3">
                                <label for="date" class="form-label">{{ $t('maintenance.date') }}</label>
                                <VueDatePicker v-model="editedMaintenance.date" :enable-time-picker="false"
                                    :format="format" :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                            </div>
                            <div class="mb-3">
                                <label for="price" class="form-label">{{ $t('maintenance.price') }}</label>
                                <input type="text" v-model="editedMaintenance.price">
                            </div>
                            <div class="mb-3">
                                <label for="km" class="form-label">{{ $t('maintenance.km') }}</label>
                                <input type="text" v-model="editedMaintenance.km">
                            </div>
                            <div class="mb-3">
                                <label for="note" class="form-label">{{ $t('maintenance.note') }}</label>
                                <input type="text" v-model="editedMaintenance.note">
                            </div>
                        </form>
                    </div>
                    <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('maintenance.close')
                        }}</button>
                        <button type="button" @click="saveChanges" class="btn btn-primary">{{ $t('maintenance.save')
                        }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal add -->
        <div class="modal fade" id="maintenanceAddModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ $t('maintenance.addHeading') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form v-if="selectedVehicle.deviceID">
                            <div class="mb-3">
                                <label for="maintenanceCompany" class="form-label">{{ $t('maintenance.company') }}</label>
                                <input type="text" v-model="addMaintenance.maintenanceCompany">
                            </div>
                            <div class="mb-3">
                                <label for="date" class="form-label">{{ $t('maintenance.date') }}</label>
                                <VueDatePicker v-model="addMaintenance.date" :enable-time-picker="false" :format="format" :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                            </div>
                            <div class="mb-3">
                                <label for="price" class="form-label">{{ $t('maintenance.price') }}</label>
                                <input type="text" v-model="addMaintenance.price">
                            </div>
                            <div class="mb-3">
                                <label for="km" class="form-label">{{ $t('maintenance.km') }}</label>
                                <input type="text" v-model="addMaintenance.km">
                            </div>
                            <div class="mb-3">
                                <label for="note" class="form-label">{{ $t('maintenance.note') }}</label>
                                <input type="text" v-model="addMaintenance.note">
                            </div>
                        </form>
                    </div>
                    <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('maintenance.close')
                        }}</button>
                        <button type="button" @click="addButton" class="btn btn-primary">{{ $t('maintenance.save')
                        }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, computed } from 'vue'
import VueJwtDecode from 'vue-jwt-decode'
import langChange from '@/components/admin/js/langChange'
import exportToExcelMixin from '@/components/admin/js/exportToExcel'
import app from '@/main'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons);

export default {
    mixins: [exportToExcelMixin, langChange],
    components: {
        SideBar,
        NavBar,
        DataTable,
        VueDatePicker,
    },
    data() {
        return {
            userID: {
                id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID,
                hash: window.location.hash.replace('#/', '')
            },
            formText: null,
            vehicles: [],
            selectedVehicle: '',
            maintenance: [],
            editedMaintenance: [],
            addMaintenance: [],
            saveChangesText: '',
            errorText: '',
            date: null,
            format: null,
            isSuccess: true,
            columns: [
                { data: (data, type, row, meta) => meta.row + 1 },
                { data: 'plate' },
                { data: 'maintenanceCompany' },
                { data: 'date' },
                { data: 'km' },
                { data: 'price' },
                { data: 'note' },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-settings edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    {
                        text: this.$t('tax.convertExcel'),
                        action: this.exportExcel
                    }
                ],
                language: {
                    url: this.lang()
                },
            },
        };
    },
    computed: {
        getStyle() {
            return {
                color: this.isSuccess ? 'green' : 'red'
            }
        }
    },
    watch: {
        '$i18n.locale': 'watchLang',
    },
    mounted() {
        this.getVehicles()
        const self = this
        const year = ref(new Date().getFullYear())
        this.format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        $('#maintenanceDatatable').on('click', '.edit-button', function () {
            const maintenanceID = $(this).data('id')
            const ins = self.maintenance.find(ins => ins.id === maintenanceID)
            if (ins) {
                self.editedMaintenance.id = ins.id
                self.editedMaintenance.date = ins.date
                self.editedMaintenance.price = ins.price
                self.editedMaintenance.carID = ins.carID
                self.editedMaintenance.km = ins.km
                self.editedMaintenance.note = ins.note
                self.editedMaintenance.maintenanceCompany = ins.maintenanceCompany
                self.saveChangesText = null
                $('#maintenanceEditModal').modal('show')
            }
        })
        $('#maintenanceDatatable').on('click', '.delete-button', function () {
            const insID = $(this).data('id');
            self.delete(insID);
        })

    },
    methods: {

        async getVehicles() {
            await axios.post('getVehicle', this.userID)
                .then(response => {
                    this.vehicles = response.data.data
                })
                .catch(error => {
                    this.formText = error.message
                });
        },
        async information() {

            await axios.post('information-maintenance', this.selectedVehicle)
                .then(response => {
                    if (response.status === 200) {
                        this.formText = null
                        this.maintenance = response.data.data
                        $('#maintenanceModal').modal('show')
                    }
                    if (response.status === 204) {
                        this.formText = this.$t('maintenance.empty')
                    }
                    if (response.status === 203) {
                        this.formText = this.$t('maintenance.carSelectError')
                    }

                })
                .catch(error => {
                    this.formText = error.message
                });
        },
        async saveChanges() {

            const editedMaintenanceCopy = { ...this.editedMaintenance }
            await axios.post('update-maintenance', editedMaintenanceCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = this.$t('maintenance.updateSuccess')
                        this.updateTableRow(editedMaintenanceCopy.id)
                    }
                    if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        updateTableRow(updatedRowId) {
            const updatedMaintenance = this.maintenance.find(ins => ins.id === updatedRowId)
            if (updatedMaintenance) {
                updatedMaintenance.km = this.editedMaintenance.km
                updatedMaintenance.price = this.editedMaintenance.price
                updatedMaintenance.note = this.editedMaintenance.note
                updatedMaintenance.maintenanceCompany = this.editedMaintenance.maintenanceCompany
                updatedMaintenance.date = this.formatDate(new Date(this.editedMaintenance.date))
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz ve iki basamaklı hale getiriyoruz
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async delete(insID) {
            await axios.delete(`delete-maintenance/${insID}`)
                .then(response => {
                    if (response.data.status === true) {
                        this.maintenance = this.maintenance.filter(ins => ins.id !== insID)
                    }
                    if (response.status === 203) {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    alert(error.message)
                })
        },
        add() {
            if (this.selectedVehicle.id) {
                this.formText = null
                this.saveChangesText = null
                $('#maintenanceAddModal').modal('show')
            }
            else {
                this.formText = this.$t('maintenance.carSelectError')
            }

        },
        async addButton() {
            const addMaintenanceCopy = { ...this.addMaintenance }
            addMaintenanceCopy.carID = this.selectedVehicle.id
            await axios.post('add-maintenance', addMaintenanceCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = this.$t('maintenance.success')
                    }
                    if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        watchLang(newLocale, oldLocale) {
            this.dataTableOptionsWithButtons.buttons[0].text = this.$t('maintenance.convertExcel')
        },
        exportExcel() {
            const columnName = ['id', this.$t('maintenance.company'), this.$t('maintenance.note'), this.$t('maintenance.km'), this.$t('maintenance.date'),this.$t('maintenance.price'),this.$t('maintenance.plate')]
            const taxesWithoutCarID = this.maintenance.map(tax => {
                const taxWithoutCarID = {};
                Object.keys(tax).forEach(key => {
                    if (key !== 'carID') {
                        taxWithoutCarID[key] = tax[key];
                    }
                });
                return taxWithoutCarID;
            });
            this.exportToExcel(taxesWithoutCarID, "maintenance", columnName)
        }

    }
}
</script>
  
<style scoped>
#formText {
    color: red;
}

.text-success {
    color: green;
}

#taxesModal {
    margin-right: auto;
}

.custom-modal {
    max-width: 90%;
}

input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
</style>
   