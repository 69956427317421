// exportToExcelMixin.js
import ExcelJS from 'exceljs';

export default {
  methods: {
    exportToExcel(datas, fileName, columnName) {
      if (datas.length === 0) {
        return;
      }
      
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(fileName);
      fileName = fileName,'.xlsx'
      // Başlık sütunlarını ekleyin
      const headerRow = worksheet.addRow(columnName);
      headerRow.eachCell(cell => {
        cell.font = { bold: true };
      });
      // Şoför verilerini ekleyin
      datas.forEach((data, index) => {
        const rowValues = [index + 1, ...Object.values(data).slice(1)];
        worksheet.addRow(Object.values(rowValues));
      });
      // Dosyayı indirilebilir bir Blob olarak kaydedin
      workbook.xlsx.writeBuffer()
        .then(buffer => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          // Excel dosyasını indirme bağlantısını oluşturun
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName || 'exported_data.xlsx';
          a.click();
          // URL'yi temizleyin
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.error('Excel dosyası oluşturulurken hata oluştu:', error);
        });
    }
  }
};
