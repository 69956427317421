<template>
    <div class="taxesform">
        <!-- Sidebar Start -->
        <SideBar />
        <NavBar />
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <div class="wrapper">
                <div class="container">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <h2>{{ $t('tax.heading') }}</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <label for="vehicle">{{ $t('tax.vehicle') }}</label>
                                <select class="form-control" v-model="selectedVehicle">
                                    <option value="">{{ $t('tax.selectVehicle') }}</option>
                                    <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka
                                    }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <br><br>
                        <div style="display: flex; justify-content: space-between;">
                            <button type="button" class="btn btn-success" @click="vergiBilgilendirme">{{
                                $t('tax.taxInformationSearch') }}</button>
                            <button type="button" class="btn btn-info" @click="taxeAdd">{{ $t('tax.add') }}</button>
                        </div>
                        <div class="form-group">
                            <a id="errorText">{{ this.errorText }}</a>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Modal tax -->
            <div class="modal fade" id="taxesModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog custom-modal">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{ $t('tax.taxInformation') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body dataTableContainer">

                            <DataTable :data="taxes" :columns="columns" class="table table-striped table-bordered display"
                                id="taxesDatatable" :options="dataTableOptionsWithButtons">
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>{{ this.$t('tax.plate') }}</th>
                                        <th>{{ this.$t('tax.year') }}</th>
                                        <th>{{ this.$t('tax.period') }}</th>
                                        <th>{{ this.$t('tax.paymentDate') }}</th>
                                        <th>{{ this.$t('tax.edit') }}</th>
                                        <th>{{ this.$t('tax.delete') }}</th>
                                    </tr>
                                </thead>
                            </DataTable>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('tax.close')
                            }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal edit -->
            <div class="modal fade" id="taxesEditModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{ $t('tax.detailheading') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form v-if="editedTaxes.id">
                                <div class="mb-3">
                                    <label for="year" class="form-label">{{ $t('tax.year') }}</label>
                                    <VueDatePicker v-model="editedTaxes.year" year-picker :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                                </div>
                                <div class="mb-3">
                                    <label for="period" class="form-label">{{ $t('tax.period') }}</label>
                                    <select class="form-select" id="period" v-model="editedTaxes.period">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="paymentDate" class="form-label">{{ $t('tax.paymentDate') }}</label>
                                    <VueDatePicker v-model="editedTaxes.paymentDate" :enable-time-picker="false"
                                        :min-date="minDate" :max-date="maxDate" :format="format" :locale="this.changeLang()" :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')" />
                                </div>
                            </form>
                        </div>
                        <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('tax.close')
                            }}</button>
                            <button type="button" @click="saveChanges" class="btn btn-primary">{{ $t('tax.save') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal add -->
            <div class="modal fade" id="taxesAddModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{ $t('tax.addheading') }}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form v-if="selectedVehicle.deviceID">
                                <div class="mb-3">
                                    <label for="year" class="form-label">{{ $t('tax.year') }}</label>
                                    <VueDatePicker v-model="addTaxes.year" year-picker :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                                </div>
                                <div class="mb-3">
                                    <label for="period" class="form-label">{{ $t('tax.period') }}</label>
                                    <select class="form-select" id="period" v-model="addTaxes.period">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="paymentDate" class="form-label">{{ $t('tax.paymentDate') }}</label>
                                    <VueDatePicker v-model="addTaxes.paymentDate" :enable-time-picker="false"
                                        :min-date="minDateAddModal" :max-date="maxDateAddModal" :format="format" :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')" />
                                </div>
                            </form>
                        </div>
                        <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('tax.close')
                            }}</button>
                            <button type="button" @click="addTaxeButton" class="btn btn-primary">{{ $t('tax.save')
                            }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import VueJwtDecode from 'vue-jwt-decode'
import langChange from '@/components/admin/js/langChange'
import exportToExcelMixin from '@/components/admin/js/exportToExcel'
import app from '@/main'
import { ref, computed } from 'vue'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons);

export default {
    mixins: [exportToExcelMixin, langChange],
    components: {
        SideBar,
        NavBar,
        DataTable,
        VueDatePicker,
    },
    data() {
        return {
            userID: {
                id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID,
                hash: window.location.hash.replace('#/', '')
            },
            errorText: '',
            vehicles: [],
            selectedVehicle: '',
            taxes: [],
            editedTaxes: [],
            addTaxes: [],
            saveChangesText: '',
            errorText: '',
            date: null,
            minDate: null,
            maxDate: null,
            minDateAddModal: null,
            maxDateAddModal: null,
            format: null,
            isSuccess: true,
            oldValue: null,
            columns: [
                { data: (data, type, row, meta) => meta.row + 1 },
                { data: 'plaka' },
                { data: 'year' },
                { data: 'period' },
                { data: 'paymentDate' },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-settings edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,
                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    {
                        text: this.$t('tax.convertExcel'),
                        action: this.exportExcel
                    }
                ],
                language: {
                    url: this.lang()
                },


            },
        };
    },
    computed: {
        getStyle() {
            return {
                color: this.isSuccess ? 'green' : 'red'
            }
        }
    },
    mounted() {
        this.getVehicles()
        const self = this
        const year = ref(new Date().getFullYear())
        this.minDateAddModal = computed(() => new Date(this.addTaxes.year, 0, 1))
        this.maxDateAddModal = computed(() => new Date(this.addTaxes.year, 11, 31))
        this.format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }
        $('#taxesDatatable').on('click', '.edit-button', function () {
            const taxesID = $(this).data('id')
            const tax = self.taxes.find(tax => tax.id === taxesID)
            if (tax) {
                self.editedTaxes.id = tax.id
                self.editedTaxes.year = tax.year
                self.editedTaxes.period = tax.period
                self.editedTaxes.paymentDate = tax.paymentDate
                self.editedTaxes.carID = tax.carID
                self.minDate = computed(() => new Date(self.editedTaxes.year, 0, 1))
                self.maxDate = computed(() => new Date(self.editedTaxes.year, 11, 31))
                self.saveChangesText = null
                self.oldValue = tax.year
                $('#taxesEditModal').modal('show')
            }
        })
        $('#taxesDatatable').on('click', '.delete-button', function () {
            const taxeID = $(this).data('id');
            self.deleteTaxe(taxeID);
        })

    },
    methods: {
        async getVehicles() {
            await axios.post('getVehicle', this.userID)
                .then(response => {
                    this.vehicles = response.data.data;
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
        async vergiBilgilendirme() {
            await axios.post('taxesData', this.selectedVehicle)
                .then(response => {
                    if (response.status === 200) {
                        this.errorText = ''
                        this.taxes = response.data.data
                        $('#taxesModal').modal('show')
                    }
                    if (response.status === 203) {
                        this.errorText = this.$t('tax.carSelectError')
                    }
                    if (response.status === 204) {
                        this.errorText = this.$t('tax.empty')
                    }

                })
                .catch(error => {
                    this.errorText = error.message

                });
        },
        async saveChanges() {

            const editedTaxesCopy = { ...this.editedTaxes }
            await axios.put(`update-tax`, editedTaxesCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = this.$t('tax.updateSuccess')
                        this.updateTableRow(editedTaxesCopy.id)
                    }
                    if (response.status === 204) {
                        this.isSuccess = false
                        this.saveChangesText = this.$t('tax.failed')
                    }
                    if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        updateTableRow(updatedRowId) {
            const updatedTax = this.taxes.find(tax => tax.id === updatedRowId)
            if (updatedTax) {
                updatedTax.year = this.editedTaxes.year
                updatedTax.period = this.editedTaxes.period
                updatedTax.paymentDate = this.formatDate(new Date(this.editedTaxes.paymentDate))
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz ve iki basamaklı hale getiriyoruz
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async deleteTaxe(taxeID) {

            await axios.delete(`delete-tax/${taxeID}`)
                .then(response => {
                    if (response.data.status === true) {
                        this.taxes = this.taxes.filter(tax => tax.id !== taxeID)
                    } else {
                        alert(this.$t('insurance.failed'))
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        taxeAdd() {
            if (this.selectedVehicle.deviceID) {
                this.errorText = null
                this.saveChangesText = null
                $('#taxesAddModal').modal('show')
            }
            else {
                this.errorText = this.$t('tax.carSelectError')
            }

        },
        async addTaxeButton() {
            this.addTaxes.carID = this.selectedVehicle.id
            const addTaxesCopy = { ...this.addTaxes }
            await axios.post('add-tax', addTaxesCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = this.$t('tax.success')
                    }
                    if (response.status === 204) {
                        this.isSuccess = false
                        this.saveChangesText = this.$t('tax.failed')
                    }
                    if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = 'ss'
                })
        },
        watchLang(newLocale, oldLocale) {
            this.dataTableOptionsWithButtons.buttons[0].text = this.$t('tax.convertExcel')
        },
        exportExcel() {
            const columnName = ['id', this.$t('tax.paymentDate'), this.$t('tax.period'), this.$t('tax.year'), this.$t('tax.plate')]
            const taxesWithoutCarID = this.taxes.map(tax => {
                const taxWithoutCarID = {};
                Object.keys(tax).forEach(key => {
                    if (key !== 'carID') {
                        taxWithoutCarID[key] = tax[key];
                    }
                });
                return taxWithoutCarID;
            });
            this.exportToExcel(taxesWithoutCarID, "taxes", columnName)
        }
    },
    watch: {
        '$i18n.locale': 'watchLang',
        'addTaxes.year': function (newValue) {
            // Yeni bir tarih seçildiğinde buraya gelecek
            this.addTaxes.paymentDate = null
        },
        'editedTaxes.year': function (newValue) {
            // Yeni bir tarih seçildiğinde buraya gelecek
            if (this.oldValue !== newValue) {
                this.editedTaxes.paymentDate = null
            }
        }
    },
}
</script>
  
<style scoped>
#errorText {
    color: red;
}

.text-success {
    color: green;
}

#taxesModal {
    margin-right: auto;
}

.custom-modal {
    max-width: 90%;
}
</style>
   