<template>
    <div>
        <SideBar />
        <NavBar />
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">

            <div class="container">
                <div class="wrapper">
                <form @submit.prevent="surusDegerlendirme">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>{{ $t('shuby.heading') }}</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label>{{ $t('shuby.vehicle') }}</label>
                            <select class="form-control" v-model="vehicleInformation" required="" autofocus="">
                                <option value="">{{ $t('shuby.selectVehicle') }}</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{
                                    vehicle.plaka
                                }}</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <button class="btn btn-lg btn-primary btn-block" type="submit">
                        {{ $t('shuby.submit') }}</button><br><br>
                    <div class="form-group">
                        <a id="errorText">{{ this.errorText }}</a>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="shubyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog custom-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body dataTableContainer">
                        <DataTable :data="shuby" :columns="columns" class="table table-striped table-bordered display"
                            :options="dataTableOptions">
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>{{ this.$t('shuby.deviceID') }}</th>
                                    <th>{{ this.$t('shuby.plate') }}</th>
                                    <th>{{ this.$t('shuby.veryEfficient') }}</th>
                                    <th>{{ this.$t('shuby.efficient') }}</th>
                                    <th>{{ this.$t('shuby.lessEfficient') }}</th>
                                    <th>{{ this.$t('shuby.inefficient') }}</th>
                                    <th>{{ this.$t('shuby.veryInefficient') }}</th>
                                    <th>{{ this.$t('shuby.startTime') }}</th>
				    <th>{{ this.$t('shuby.finishTime') }}</th>
                                </tr>
                            </thead>
                        </DataTable>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('shuby.close')
                        }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div></template>
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import exportToExcelMixin from '@/components/admin/js/exportToExcel'
import langChange from '@/components/admin/js/langChange'
import VueJwtDecode from 'vue-jwt-decode'
import app from '@/main'
DataTable.use(DataTableLength)


DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons)
export default {
    mixins: [exportToExcelMixin, langChange],
    components: {
        SideBar,
        NavBar,
        DataTable,
    },
    data() {
        return {
            vehicles: [],
            vehicleInformation: '',
            editedVehicle: {},
            errorText: '',
            shuby: [],
            userID: {
                id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID,
                hash: window.location.hash.replace('#/', '')
            },
            columns: [
                { data: null, render: (data, type, row, meta) => `${meta.row + 1}` },
                { data: 'deviceID' },
                { data: 'plaka' },
                { data: 'cokVerimli' },
                { data: 'verimli' },
                { data: 'azVerimli' },
                { data: 'verimsiz' },
                { data: 'cokVerimsiz' },
                { data: 'startTime' },
		{ data: 'finishTime' }
            ],
            dataTableOptions: {
                responsive: true,
                autoWidth: false,
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    {
                        text: this.$t('shuby.convertExcel'),
                        action: this.exportExcel
                    }
                ],
                lengthMenu: [10, 25, 50, 100], //
                language: {
                    url: this.lang()
                },
            },
        }
    },
    mounted() {
        this.getVehicles()
    },
    methods: {
        getVehicles() {
            axios.post('getVehicle', this.userID)
                .then(response => {
                    this.vehicles = response.data.data
                })
                .catch(error => {
                    console.log(error.message)
                })
        },
        surusDegerlendirme() {
            axios.post('shubyData', this.vehicleInformation)
                .then(response => {
                    if (response.status === 200) {
                        this.errorText = ''
                        this.shuby = response.data.data
                        $('#shubyModal').modal('show')
                    }
                    if (response.status === 204) {
                        this.errorText = this.$t('shuby.empty')
                    }
                    if (response.status === 203) {
                        this.errorText = response.data.message
                    }
                })
                .catch(error => {
                    this.errorText = error.message
                })

        },
        exportExcel() {
            const columnName = ['id',
                this.$t('shuby.deviceID'),
                this.$t('shuby.veryEfficient'),
                this.$t('shuby.efficient'),
                this.$t('shuby.lessEfficient'),
                this.$t('shuby.inefficient'),
                this.$t('shuby.veryInefficient'),
                this.$t('shuby.startTime'),
		this.$t('shuby.finishTime'),
                this.$t('shuby.plate')]
            console.log(this.shuby)
            this.exportToExcel(this.shuby, "shuby", columnName)
        },
        watchLang(newLocale, oldLocale) {
            this.dataTableOptions.buttons[0].text = this.$t('shuby.convertExcel')
        }
    },
    watch: {
        '$i18n.locale': 'watchLang'
    },
}
</script>
<style scoped>
#errorText {
    color: red
}

#successText {
    color: green
}

#shubyModal {
    margin-right: auto
}

.custom-modal {
    max-width: 90%
        /* Adjust this value as needed */
}
</style>
  
