import app from '@/main'

export default {
    methods: {
        // datatable için dil ayarı
        lang() {
            if (app.config.globalProperties.$cookies.get('lang') === 'en') {
                return 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/en-GB.json'
            }
            if (app.config.globalProperties.$cookies.get('lang') === 'tr') {
                return 'https://cdn.datatables.net/plug-ins/1.13.6/i18n/tr.json'
            }
        },
        // datepicker için dil ayarı
        changeLang() {
            if (app.config.globalProperties.$cookies.get('lang') === 'tr') {
              return 'tr-Tr'
            }
            if (app.config.globalProperties.$cookies.get('lang') === 'en') {
              return 'en-US'
            }
          }
    }
}
