// import { createI18n } from 'vue-i18n'
import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler'
import en from '@/locales/en.json'
import tr from '@/locales/tr.json'
export default createI18n({
  // locale:  window.navigator.language.split('-')[0], // if you want the app to detect the browser's default language
  locale: 'en', // default language
  fallbackLocale: 'tr', // fallback language in case a translation is not found
  messages: {
    en,
    tr
  }
})
