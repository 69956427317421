<template>
  <div class="login">
    <div v-if="loading"
      style="height:100%; width:100%; display:grid; place-items:center; z-index:9999; position:absolute; background-color:#000000c9;">
      <!-- Loading gif veya spinner buraya eklenebilir -->
      <div style="display:grid; place-items:center;">
        <img src="images/load3.gif" style="width:128px;">
        <h1 style="color:white; text-align:center;">{{ $t('login.loading') }}</h1>
      </div>
    </div>
    <div class="header_section">
      <div class="header_top" style="background-color:transparent;">
        <div class="container-fluid header_top_container">
          <div class="lang_box dropdown">
            <a title="Language" class="nav-link" @click="changeLanguage('en')" data-toggle="dropdown"
              aria-expanded="true">
              <img :src="selectedFlag" alt="flag" class="" title="United Kingdom">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="changeLanguage('tr')">
                <img src="images/flag/flag-tr.png" class="" alt="flag">
              </a>
            </div>
          </div>
          <div class="contact_nav">
            <a href="">
              <i class="fa fa-phone" aria-hidden="true"></i>
              <span>
                {{ $t('header.contact.call') }}
              </span>
            </a>
            <a href="">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              <span>
                E-Mail:demo@veryfleet.net
              </span>
            </a>
            <a href="">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              <span>
                {{ $t('header.contact.location') }}
              </span>
            </a>
          </div>
          <div class="social_box">
            <a href="">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href="">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a href="">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a href="">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="login-box">
      <h2>{{ $t('login.loginTitle') }}</h2>
      <form @submit.prevent="submitForm">
        <div class="row">
          <p id="errorText" type="text" v-text="errorText"></p>
        </div>
        <div class="user-box">
          <label>{{ $t('login.usernamePlaceholder') }}</label>
          <input v-model="user.email" type="email" name="username" id="username" class="form__input" required>
        </div>
        <div class="user-box">
          <label for="">{{ $t('login.passwordPlaceholder') }}</label>
          <input v-model="user.password" type="password" name="password" id="password" class="form__input" required>
        </div>
        <button type="submit">
          <a id="loginBtn">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            {{ $t('login.submitButton') }}
          </a>
        </button>
        <a id="homePageBtn" @click="goToHomePage" style="float:left; color:cyan;">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          {{ $t('login.returnButton') }}
        </a>        
      </form>
    </div>
  </div>
</template>
<script>
import axios from '@/server/axios'
import app from '@/main'
import router from '@/router/index'
export default {
  data() {
    return {
      result: {},
      user: {
        email: '',
        password: ''
      },
      submitValue: 'Submit',
      errorText: '',
      selectedFlag: this.lang(),
      loading: false
    }
  },
  mounted() {
    this.adjustDivHeight()
  },
  created() {
    window.addEventListener('resize', this.adjustDivHeight)
  },
  methods: {
    async submitForm() {
      this.loading = true
      // Form verilerini bir API'ye post edebilirsiniz.
      // Örnek olarak axios kullanarak:
      axios.post('login', this.user)
        .then(response => {
          this.submitValue = this.$t('login.submitButton')
          if (response.status === 200) {
            const token = response.data.authorization.token
            if (app.config.globalProperties.$cookies.set('authToken', token) && app.config.globalProperties.$cookies.set('lang', this.$i18n.locale)) {
              router.push('admin')
              window.location.reload()
            }
          }
          if (response.status === 204) {
            this.loading = false
            this.errorText = this.$t('login.errorMessages.incorrectInfo')
          } 
          if (response.status === 203) {
            this.loading = false
            this.errorText = this.$t('login.errorMessages.superError')
          }
        }
        )
        .catch(error => {
          this.loading = false
          this.errorText = this.$t('login.errorMessages.error') + ' : ' + error
        })
    },
    adjustDivHeight() {
      const windowHeight = window.innerHeight
      // const windowWidth = window.screen.width
      const divElements = document.querySelectorAll('.login')
      divElements.forEach(function (div) {
        div.style.height = windowHeight + 'px'
        // div.style.width = windowWidth + 'px'
      })
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang
      if (lang === 'tr') {
        this.selectedFlag = 'images/flag/flag-tr.png'
      } else if (lang === 'en') {
        this.selectedFlag = 'images/flag/flag-en.png'
      }
    },
    lang() {
      return 'images/flag/flag-' + this.$i18n.locale + '.png'
    },
    goToHomePage() {
      this.$router.push({ name: 'home' })
    },
  }
}
</script>
<style scoped>
#errorText {
  color: red;
}
</style>
