<template>
    <div class="taxesform">
        <SideBar />
        <NavBar />
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            <div class="wrapper">
                <div class="container">
                    <form>
                        <div class="form-group">
                            <h2>{{ $t('comprehensiveInsurance.heading') }}</h2>
                            <label for="vehicle">{{ $t('comprehensiveInsurance.vehicles') }}</label>
                            <select v-model="selectedVehicle">
                                <option value="">{{ $t('comprehensiveInsurance.selectVehicle') }}</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle">{{ vehicle.plaka }}
                                </option>
                            </select>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <button type="button" class="btn btn-success" @click="information">{{
                                $t('comprehensiveInsurance.search') }}</button>
                            <button type="button" class="btn btn-info" @click="add">{{ $t('comprehensiveInsurance.add')
                            }}</button>
                        </div>
                        <div class="form-group">
                            <a id="formText">{{ this.formText }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal information -->
        <div class="modal fade" id="kaskoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog custom-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ $t('comprehensiveInsurance.heading') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body dataTableContainer">

                        <DataTable :data="kasko" :columns="columns" class="table table-striped table-bordered display"
                            id="kaskoDatatable" :options="dataTableOptionsWithButtons">
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>{{ this.$t('comprehensiveInsurance.plate') }}</th>
                                    <th>{{ this.$t('comprehensiveInsurance.company') }}</th>
                                    <th>{{ this.$t('comprehensiveInsurance.year') }}</th>
                                    <th>{{ this.$t('comprehensiveInsurance.startDate') }}</th>
                                    <th>{{ this.$t('comprehensiveInsurance.finishDate') }}</th>
                                    <th>{{ this.$t('comprehensiveInsurance.edit') }}</th>
                                    <th>{{ this.$t('comprehensiveInsurance.delete') }}</th>
                                </tr>
                            </thead>
                        </DataTable>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{
                            $t('comprehensiveInsurance.close') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal edit -->
        <div class="modal fade" id="kaskoEditModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ $t('comprehensiveInsurance.editHeading') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form v-if="editedKasko.id">
                            <div class="mb-3">
                                <label for="kaskoCompany" class="form-label">{{ $t('comprehensiveInsurance.company')
                                }}</label>
                                <input type="text" v-model="editedKasko.kaskoCompany">
                            </div>
                            <div class="mb-3">
                                <label for="year" class="form-label">{{ $t('comprehensiveInsurance.year') }}</label>
                                <VueDatePicker v-model="editedKasko.year" year-picker :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                            </div>
                            <div class="mb-3">
                                <label for="startDate" class="form-label">{{ $t('comprehensiveInsurance.startDate')
                                }}</label>
                                <VueDatePicker v-model="editedKasko.startDate" :enable-time-picker="false"
                                    :min-date="minDate" :max-date="maxDate" :format="format" :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                            </div>
                            <div class="mb-3">
                                <label for="finishDate" class="form-label">{{ $t('comprehensiveInsurance.finishDate')
                                }}</label>
                                <VueDatePicker v-model="editedKasko.finishDate" :enable-time-picker="false"
                                    :min-date="minDate" :max-date="maxDateFinish" :format="format" :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                            </div>
                        </form>
                    </div>
                    <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{
                            $t('comprehensiveInsurance.close') }}</button>
                        <button type="button" @click="saveChanges" class="btn btn-primary">{{
                            $t('comprehensiveInsurance.save') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal add -->
        <div class="modal fade" id="kaskoAddModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ $t('comprehensiveInsurance.addHeading') }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form v-if="selectedVehicle.deviceID">
                            <div class="mb-3">
                                <label for="year" class="form-label">{{ $t('comprehensiveInsurance.company') }}</label>
                                <input type="text" v-model="addKasko.kaskoCompany" >
                            </div>
                            <div class="mb-3">
                                <label for="year" class="form-label">{{ $t('comprehensiveInsurance.year') }}</label>
                                <VueDatePicker v-model="addKasko.year" year-picker :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')" />
                            </div>
                            <div class="mb-3">
                                <label for="startDate" class="form-label">{{ $t('comprehensiveInsurance.startDate')
                                }}</label>
                                <VueDatePicker v-model="addKasko.startDate" :enable-time-picker="false"
                                    :min-date="minDateAddModal" :max-date="maxDateAddModal" :format="format" :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                            </div>
                            <div class="mb-3">
                                <label for="finishDate" class="form-label">{{ $t('comprehensiveInsurance.finishDate')
                                }}</label>
                                <VueDatePicker v-model="addKasko.finishDate" :enable-time-picker="false"
                                    :min-date="minDateAddModal" :max-date="maxDateFinishAddModal" :format="format" :locale="this.changeLang()" 
                                        :cancelText="this.$t('datepicker.cancelText')" :selectText="this.$t('datepicker.selectText')"/>
                            </div>
                        </form>
                    </div>
                    <a id="changesText" :style="getStyle">{{ this.saveChangesText }}</a>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{
                            $t('comprehensiveInsurance.close') }}</button>
                        <button type="button" @click="addButton" class="btn btn-primary">{{
                            $t('comprehensiveInsurance.save') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import SideBar from '@/components/admin/Sidebar.vue'
import NavBar from '@/components/admin/Navbar.vue'
import axios from '@/server/axios'
import DataTable from 'datatables.net-vue3'
import DataTableLib from 'datatables.net-bs5'
import Buttons from 'datatables.net-buttons-bs5'
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5'
import DataTableLength from 'datatables.net-bs5/js/dataTables.bootstrap5'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, computed } from 'vue'
import VueJwtDecode from 'vue-jwt-decode'
import langChange from '@/components/admin/js/langChange'
import exportToExcelMixin from '@/components/admin/js/exportToExcel'
import app from '@/main'

DataTable.use(DataTableLength)
DataTable.use(DataTableLib)
DataTable.use(ButtonsHtml5)
DataTable.use(Buttons);

export default {
    mixins: [exportToExcelMixin, langChange],
    components: {
        SideBar,
        NavBar,
        DataTable,
        VueDatePicker,
    },
    data() {
        return {
            userID: {
                id: VueJwtDecode.decode(app.config.globalProperties.$cookies.get('authToken')).userID,
                hash: window.location.hash.replace('#/', '')
            },
            formText: null,
            vehicles: [],
            selectedVehicle: '',
            kasko: [],
            editedKasko: [],
            addKasko: [],
            saveChangesText: '',
            errorText: '',
            date: null,
            minDate: null,
            maxDate: null,
            maxDateFinish: null,
            minDateAddModal: null,
            maxDateAddModal: null,
            maxDateFinishAddModal: null,
            format: null,
            isSuccess: true,
            oldValue: null,
            columns: [
                { data: (data, type, row, meta) => meta.row + 1 },
                { data: 'plate' },
                { data: 'kaskoCompany' },
                { data: 'year' },
                { data: 'startDate' },
                { data: 'finishDate' },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-settings edit-button" data-id="' + row.id + '"></button>';
                    }
                },
                {
                    data: null,
                    render: (data, type, row) => {
                        return '<button class="ti ti-trash delete-button" data-id="' + row.id + '"></button>';
                    }
                }
            ],
            dataTableOptionsWithButtons: {
                responsive: true,
                autoWidth: false,

                lengthMenu: [10, 25, 50, 100], //
                dom: 'B<"row"<"col-md-6"l><"col-md-6"f>>rtip',
                buttons: [
                    {
                        text: this.$t('tax.convertExcel'),
                        action: this.exportExcel
                    }
                ],
                language: {
                    url: this.lang()
                },
            },
        };
    },
    computed: {
        getStyle() {
            return {
                color: this.isSuccess ? 'green' : 'red'
            }
        }
    },
    watch: {
        '$i18n.locale': 'watchLang',
        'addKasko.year': function (newValue) {
            // Yeni bir tarih seçildiğinde buraya gelecek
            this.addKasko.startDate = null
            this.addKasko.finishDate = null
        },
        'editedKasko.year': function (newValue) {
            // Yeni bir tarih seçildiğinde buraya gelecek
            if (this.oldValue !== newValue) {
                this.editedKasko.startDate = null
                this.editedKasko.finishDate = null
            }
        }
    },
    mounted() {
        this.getVehicles()
        const self = this
        const year = ref(new Date().getFullYear())
        this.minDateAddModal = computed(() => new Date(this.addKasko.year, 0, 1))
        this.maxDateAddModal = computed(() => new Date(this.addKasko.year, 11, 31))
        this.maxDateFinishAddModal = computed(() => new Date(this.addKasko.year + 1, 11, 31))
        this.format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        $('#kaskoDatatable').on('click', '.edit-button', function () {
            const kaskoID = $(this).data('id')
            const ins = self.kasko.find(ins => ins.id === kaskoID)
            if (ins) {
                self.editedKasko.id = ins.id
                self.editedKasko.year = ins.year
                self.editedKasko.startDate = ins.startDate
                self.editedKasko.carID = ins.carID
                self.editedKasko.plate = ins.plate
                self.editedKasko.finishDate = ins.finishDate
                self.editedKasko.kaskoCompany = ins.kaskoCompany
                self.minDate = computed(() => new Date(self.editedKasko.year, 0, 1))
                self.maxDate = computed(() => new Date(self.editedKasko.year, 11, 31))
                self.maxDateFinish = computed(() => new Date(Number(self.editedKasko.year) + 1, 11, 31))
                self.saveChangesText = null
                self.oldValue = ins.year
                $('#kaskoEditModal').modal('show')
            }
        })
        $('#kaskoDatatable').on('click', '.delete-button', function () {
            const insID = $(this).data('id');
            self.delete(insID);
        })

    },
    methods: {

        async getVehicles() {
            await axios.post('getVehicle', this.userID)
                .then(response => {
                    this.vehicles = response.data.data;
                })
                .catch(error => {
                    console.log(error.message);
                });
        },
        async information() {

            await axios.post('information', this.selectedVehicle)
                .then(response => {
                    if (response.status === 200) {
                        this.formText = null
                        this.kasko = response.data.data
                        $('#kaskoModal').modal('show')
                    }
                    if (response.status === 204) {
                        this.formText = this.$t('comprehensiveInsurance.empty')
                    }
                    if (response.status === 203) {
                        this.formText = this.$t('comprehensiveInsurance.carSelectError')
                    }

                })
                .catch(error => {
                    this.formText = error.message
                });
        },
        async saveChanges() {

            const editedKaskoCopy = { ...this.editedKasko }
            await axios.post('update', editedKaskoCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = this.$t('comprehensiveInsurance.updateSuccess')
                        this.updateTableRow(editedKaskoCopy.id)
                    }
                    if (response.status === 204) {
                        this.isSuccess = false
                        this.saveChangesText = this.$t('comprehensiveInsurance.failed')
                    }
                    if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        updateTableRow(updatedRowId) {
            const updatedKasko = this.kasko.find(ins => ins.id === updatedRowId)
            if (updatedKasko) {
                updatedKasko.year = this.editedKasko.year
                updatedKasko.kaskoCompany = this.editedKasko.kaskoCompany
                updatedKasko.startDate = this.formatDate(new Date(this.editedKasko.startDate))
                updatedKasko.finishDate = this.formatDate(new Date(this.editedKasko.finishDate))
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay, 0-11 aralığında olduğu için +1 ekliyoruz ve iki basamaklı hale getiriyoruz
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async delete(insID) {
            await axios.delete(`delete/${insID}`)
                .then(response => {
                    if (response.data.status === true) {
                        this.kasko = this.kasko.filter(ins => ins.id !== insID)
                    } if (response.status === 203) {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    alert(error.message)
                })
        },
        add() {
            if (this.selectedVehicle.id) {
                this.formText = null
                this.saveChangesText = null
                $('#kaskoAddModal').modal('show')
            }
            else {
                this.formText = this.$t('comprehensiveInsurance.carSelectError')
            }

        },
        async addButton() {
            const addKaskoCopy = { ...this.addKasko }
            addKaskoCopy.carID = this.selectedVehicle.id
            addKaskoCopy.numberPlate = this.selectedVehicle.plaka
            await axios.post('add', addKaskoCopy)
                .then(response => {
                    if (response.status === 200) {
                        this.isSuccess = true
                        this.saveChangesText = this.$t('comprehensiveInsurance.success')
                    }
                    if (response.status === 204) {
                        this.isSuccess = false
                        this.saveChangesText = this.$t('comprehensiveInsurance.failed')
                    }
                    if (response.status === 203) {
                        this.isSuccess = false
                        this.saveChangesText = response.data.message
                    }
                })
                .catch(error => {
                    this.isSuccess = false
                    this.saveChangesText = error.message
                })
        },
        watchLang(newLocale, oldLocale) {
            this.dataTableOptionsWithButtons.buttons[0].text = this.$t('insurance.convertExcel')
        },
        exportExcel() {
            const columnName = ['id', this.$t('comprehensiveInsurance.year'), this.$t('comprehensiveInsurance.startDate'), this.$t('comprehensiveInsurance.finishDate'), this.$t('comprehensiveInsurance.company'),this.$t('comprehensiveInsurance.plate')]
            const taxesWithoutCarID = this.kasko.map(tax => {
                const taxWithoutCarID = {};
                Object.keys(tax).forEach(key => {
                    if (key !== 'carID') {
                        taxWithoutCarID[key] = tax[key];
                    }
                });
                return taxWithoutCarID;
            });
            this.exportToExcel(taxesWithoutCarID, "comprehensiveInsurance", columnName)
        }

    }
}
</script>
  
<style scoped>
#formText {
    color: red;
}

.text-success {
    color: green;
}

#taxesModal {
    margin-right: auto;
}

.custom-modal {
    max-width: 90%;
}

input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
</style>
   